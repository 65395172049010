<template>
  <section class="page-content">
    <p class="description">
      W zakładce <strong>dane wystawcy</strong> uzupełnij kluczowe informacje o
      firmie niezbędne do przygotowania przez organizatora materiałów
      promocyjnych na Festiwal Pracy JOBICON.
    </p>
    <download-data page-type="_ed_" v-if="!disabled" />
    <form class="main-form">
      <h2>Dane wystawcy:</h2>
      <v-input
        id="_ed_name"
        name="_ed_name"
        label="Oficjalna nazwa firmy wystawcy, wpisana bez rodzaju spółki, np. bez S.A. / Sp. z o.o."
        placeholder="Nazwa firmy"
        :required="true"
        :defaultValue="formData._ed_name"
        :disabled="disabled"
        cssVariant="error"
        v-model="formData._ed_name"
        @auto-save="sendData(false)"
      />
      <div class="double-input-container">
        <v-input
          id="_ed_website"
          name="_ed_website"
          label="Adres strony do podlinkowania logotypu na stronie wydarzenia – Profil Pracodawcy lub listing ogłoszeń na Pracuj.pl"
          type="url"
          placeholder="https://przykladowa.pl"
          :required="true"
          :defaultValue="formData._ed_website"
          v-model="formData._ed_website"
          @auto-save="sendData(false)"
          :disabled="disabled"
        />
        <v-select
          id="_ed_industry"
          name="_ed_industry"
          label="Branża"
          :range="selectListIndustries"
          :required="true"
          :defaultValue="formData._ed_industry"
          v-model="formData._ed_industry"
          @auto-save="sendData(false)"
          :disabled="disabled"
        />
      </div>
      <span class="informations margin-tp-1 danger-text">* pola wymagane</span>
      <div class="space-content">
        <h2>Dodaj logo firmy:</h2>
        <p class="informations">
          Umieścimy je na stronie www wydarzenia. Prosimy o załadowanie logo o
          wysokiej jakości z możliwością skalowania. Logo powinno być załadowane
          w formacie: pdf, eps lub ai. Maksymalna waga pliku to 5 MB. Logo w
          takim formacie powinien posiadać dział marketingu lub dział graficzny
          Twojej firmy.
        </p>
        <p class="informations">
          <strong>
            Skontaktuj się z nami jeśli masz problem z wgraniem logo w poprawnym
            formacie (pdf, eps lub ai).
          </strong>
        </p>
        <v-upload
          id="_ed_logo"
          name="_ed_logo"
          label="Załaduj logo firmy"
          label-alt="Zmień logo firmy"
          file-type="vector"
          :confirmMessage="'Potwierdź dodanie logo'"
          :edition="activeEdition"
          :company="companyData.id"
          v-model="formData._ed_logo"
          :detailId="getDetailId('_ed_logo')"
          :disabled="disabled"
        />
      </div>
      <div class="space-content" v-if="userRole === 'administrator'">
        <h2>
          Dodaj logo firmy na stronę WWW (<a
            target="_blank"
            rel="nofollow noopener"
            href="https://jobicon.pracuj.pl"
            >jobicon.pracuj.pl</a
          >) | dla administratora:
        </h2>
        <p class="informations">
          W tym miejscu możesz zmienić logo firmy, które wyświetla się na
          stronie jobicon.pracuj.pl. Maksymalna waga pliku to 5 MB.<br />To pole
          widoczne jest wyłącznie dla osób z uprawnieniami administratora w
          systemie.
        </p>
        <v-upload
          id="_ed_logo_www"
          name="_ed_logo_www"
          label="Załaduj logo firmy na www"
          label-alt="Zmień logo firmy na www"
          file-type="vector"
          confirmMessage="Potwierdź dodanie logo na www"
          :edition="activeEdition"
          :company="companyData.id"
          v-model="formData._ed_logo_www"
          :disabled="disabled"
        />
      </div>
      <div class="send-btn-cont" v-if="!disabled">
        <main-btn
          content="Zapisz wersję roboczą"
          tooltip="Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia."
          :submit="true"
          :saving="loading"
          :gray="true"
          @click="sendData(false)"
        />
        <main-btn
          content="Zapisz wersję ostateczną"
          tooltip="Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia"
          :submit="true"
          :saving="loading"
          @click="sendData(true, '_ed_disabled', ignoredKeys)"
        />
      </div>
      <div v-else>
        <p>
          <strong
            >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
            dokonać zmian skontaktuj się pod adresem
            <a
              class="important-info"
              :href="`mailto:${companyGuardian.email}`"
              >{{ companyGuardian.email }}</a
            ></strong
          >
        </p>
      </div>
      <p class="text-center margin-tp-1" v-show="message">
        <strong :class="`message--${messageType}`">{{ message }}</strong>
      </p>
    </form>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import getDetailId from "@/mixins/getDetailId.js";

export default Vue.extend({
  mixins: [sendDataAndDispatch, assignDataFromDatabase, getDetailId],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      formData: {
        _ed_name: "",
        _ed_website: "",
        _ed_industry: "",
        _ed_logo: "",
        _ed_logo_www: "",
        _ed_disabled: false,
      },
      ignoredKeys: ["_ed_logo_www"],
      selectListIndustries: [
        "Handel i sprzedaż",
        "Bankowość, finanse, ubezpieczenia",
        "Energetyka",
        "Telekomunikacja i zaawansowane technologie",
        "Przemysł ciężki",
        "Budownictwo i nieruchomości",
        "Przemysł lekki",
        "Transport i logistyka",
        "IT",
        "Inżynieria",
        "Produkcja dóbr szybkozbywalnych – FMCG",
        "Marketing, media, reklama",
        "Przemysł farmaceutyczny",
        "Przemysł chemiczny",
        "Edukacja, kształcenie, szkolenie",
        "Turystyka, hotelarstwo, gastronomia",
        "Prawo, obsługa prawna",
        "E-commerce",
        "Human Resources",
        "Sektor publiczny",
        "Usługi profesjonalne",
        "Wyposażenie wnętrz",
        "Inna",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "companyData",
      "companyGuardian",
      "activeEditionOpened",
      "userRole",
      "namedDetails",
    ]),
  },
  methods: {
    checkIfPageIsDisabled() {
      this.disabled = this.formData._ed_disabled;

      if (!this.activeEditionOpened) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData("_ed_name", "");
      this.assingData("_ed_website", "");
      this.assingData("_ed_industry", "");
      this.assingData("_ed_logo", "");
      this.assingData("_ed_logo_www", this.formData._ed_logo);
      this.assingData("_ed_disabled", false, "boolean");
    },
  },
  created() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  watch: {
    companyData() {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>
